import { sumBy, uniq } from 'lodash';
import { formatDate } from '@/utils/format-utils';

type Liability = Record<string, any>;

export function getOverview(liabilities: Liability[]) {
  return {
    totalCount: liabilities.length,
    totalPurchaseValue: sumBy(liabilities, 'purchaseValue'),
    totalRedeemedValue: sumBy(liabilities, 'redeemedValue'),
    totalGrossValue: sumBy(liabilities, 'currentPFeeGrossValue'),
    totalPerformanceFee: sumBy(liabilities, 'performanceFee'),
    totalQuantity: sumBy(liabilities, 'quantity'),
    totalRepresentation: sumBy(liabilities, 'houseFundTotalRepresentation'),
  };
}

export function getFiltersOptions(liabilities: Liability[]) {
  return {
    quotaholderName: uniq(liabilities.map((item) => item.quotaholderName)).sort(),
    purchaseDate: uniq(liabilities.map((item) => item.purchaseDate)).sort().map(
      (value) => ({ value, text: formatDate(value) }),
    ),
    performanceFeeBaseDate: uniq(liabilities.map((item) => item.performanceFeeBaseDate)).sort().map(
      (value) => ({ value, text: formatDate(value) }),
    ),
  };
}

export function filterResults(liabilities: Liability[], filters: Record<string, any>) {
  return liabilities.filter((liability) => {
    const rules: boolean[] = [
      !filters.quotaholderName.length || filters.quotaholderName.includes(liability.quotaholderName),
      !filters.purchaseDate.length || filters.purchaseDate.includes(liability.purchaseDate),
      !filters.performanceFeeBaseDate.length || filters.performanceFeeBaseDate.includes(liability.performanceFeeBaseDate),
    ];

    return rules.every((rule) => rule);
  });
}
