<template>
  <v-container fluid class="overview-container">
    <v-row no-gutters>
      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Registros
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ formatNumber(overview.totalCount) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Cota Bruta
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': currentPFeeGrossPrice < 0 }">
              {{ formatQuota(currentPFeeGrossPrice) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Cota Líquida
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': currentPFeeNetPrice < 0 }">
              {{ formatQuota(currentPFeeNetPrice) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Valor aplicado total
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': overview.totalPurchaseValue < 0 }">
              {{ formatMoney(overview.totalPurchaseValue) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Valor resgatado total
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': overview.totalRedeemedValue < 0 }">
              {{ formatMoney(overview.totalRedeemedValue) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Valor bruto total
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': overview.totalGrossValue < 0 }">
              {{ formatMoney(overview.totalGrossValue) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Taxa total de performance
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': overview.totalPerformanceFee < 0 }">
              {{ formatMoney(overview.totalPerformanceFee) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              Quantidade total
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': overview.totalQuantity < 0 }">
              {{ formatNumber(overview.totalQuantity) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="2" xl="auto">
        <v-list-item two-line dense>
          <v-list-item-content>
            <v-list-item-title>
              %PL total
            </v-list-item-title>

            <v-list-item-subtitle :class="{ 'error--text': overview.totalRepresentation < 0 }">
              {{ formatPercentage(overview.totalRepresentation) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney, formatNumber, formatPercentage } from '@/utils/format-utils';

export default {
  name: 'LiabilitiesDetailsOverview',

  props: {
    overview: {
      type: Object,
      required: true,
    },

    currentPFeeGrossPrice: {
      type: Number,
      default: 0,
    },

    currentPFeeNetPrice: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    formatMoney,
    formatNumber,
    formatPercentage,
    formatQuota: (val) => formatNumber(val, { minimumFractionDigits: 8 }),
  },
};
</script>

<style lang="scss" scoped>
.overview-container {
  background: #eeee;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>
