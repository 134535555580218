<template>
  <v-menu
    offset-y
    max-width="300"
    :close-on-content-click="false"

    v-model="isPickerMenuVisible"
    v-bind="menuProps"
  >
    <template v-slot:activator="vMenuScope">
      <v-text-field
        v-bind="{
          ...dynamicFieldAttrs,
          ...$attrs,
          ...vMenuScope.attrs,
        }"

        v-on="{
          ...dynamicFieldListeners,
          ...$listeners,
          ...vMenuScope.on,
        }"

        readonly
        :value="formattedValue"
      />
    </template>

    <template v-slot:default>
      <v-date-picker
        v-bind="{ ...dynamicPickerAttrs, ...pickerProps }"

        no-title
        full-width

        locale="pt-BR"
        :value="value"

        @input="(value) => updateValue(value)"
        @change="(value) => updateValue(value, 'change')"
      />
    </template>
  </v-menu>
</template>

<script>
import moment from 'moment-loyall';

export default {
  name: 'DatePickerField',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: String,
      default: '',
    },

    menuProps: {
      type: Object,
      default: () => ({}),
    },

    pickerProps: {
      type: Object,
      default: () => ({}),
    },

    businessDaysOnly: {
      type: Boolean,
      default: false,
    },

    showPrevNext: {
      type: Boolean,
      default: false,
    },

    minDate: {
      type: String,
      default: null,
    },

    maxDate: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    isPickerMenuVisible: false,
  }),

  computed: {
    formattedValue: (vm) => {
      if (!vm.value) {
        return '';
      }

      const [year, month, day] = vm.value.split('-').map((num) => Number.parseInt(num, 10));

      return (new Date(year, month - 1, day)).toLocaleDateString('pt-BR');
    },

    dynamicFieldAttrs: (vm) => {
      const attrs = {};

      if (vm.showPrevNext) {
        attrs.prependIcon = 'mdi-chevron-left';
        attrs.appendOuterIcon = 'mdi-chevron-right';
      }

      return attrs;
    },

    dynamicFieldListeners: (vm) => {
      const listeners = {};

      if (vm.showPrevNext) {
        listeners['click:prepend'] = () => vm.prevDate();
        listeners['click:append-outer'] = () => vm.nextDate();
      }

      return listeners;
    },

    dynamicPickerAttrs: (vm) => {
      const attrs = { allowedDates: vm.getAllowedDates };

      if (vm.minDate) {
        attrs.min = vm.minDate;
      }

      if (vm.maxDate) {
        attrs.max = vm.maxDate;
      }

      return attrs;
    },
  },

  methods: {
    updateValue(value, event = 'input') {
      this.isPickerMenuVisible = false;

      this.$emit(event, value);
    },

    getAllowedDates(date) {
      return this.businessDaysOnly ? moment(date).isBusinessDay('brasil') : true;
    },

    prevDate() {
      const currentDate = moment(this.value);

      const targetDate = (this.businessDaysOnly
        ? currentDate.prevBusinessDay('brasil')
        : currentDate.subtract(1, 'day')
      );

      if (this.minDate && targetDate.isBefore(this.minDate)) {
        return;
      }

      this.updateValue(targetDate.format('YYYY-MM-DD'));
    },

    nextDate() {
      const currentDate = moment(this.value);

      const targetDate = (this.businessDaysOnly
        ? currentDate.nextBusinessDay('brasil')
        : currentDate.add(1, 'day')
      );

      if (this.maxDate && targetDate.isAfter(this.maxDate)) {
        return;
      }

      this.updateValue(targetDate.format('YYYY-MM-DD'));
    },
  },
};
</script>
