<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    item-key="_id"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip right content-class="grey darken-4">
        <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
          <v-btn
            small
            icon
            color="primary"
            class="mx-1"
            :to="`/liabilities/${item.houseFundId}?refDate=${item.refDate.substring(0, 10)}`"
            v-bind="tooltipAttrs"
            v-on="tooltipEvents"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Ver detalhes
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.refDate="{ item }">
      {{ formatDate(item.refDate) }}
    </template>

    <template v-slot:item.currentPFeeGrossPrice="{ item }">
      {{ formatQuota(item.currentPFeeGrossPrice) }}
    </template>

    <template v-slot:item.currentPFeeNetPrice="{ item }">
      {{ formatQuota(item.currentPFeeNetPrice) }}
    </template>
  </v-data-table>
</template>

<script>
import { formatDate, formatNumber } from '@/utils/format-utils';

export default {
  name: 'LiabilitiesTable',

  inheritAttrs: false,

  data: () => ({
    headers: [
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '48px',
      },

      {
        text: 'Fundo',
        value: 'houseFundName',
      },

      {
        text: 'Data',
        value: 'refDate',
      },

      {
        text: 'Cota Bruta',
        value: 'currentPFeeGrossPrice',
        align: 'end',
      },

      {
        text: 'Cota Líquida',
        value: 'currentPFeeNetPrice',
        align: 'end',
      },
    ],
  }),

  methods: {
    formatDate,
    formatNumber,
    formatQuota: (val) => formatNumber(val, { minimumFractionDigits: 8 }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>
