<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <date-picker-field
          label="Data inicial"
          prepend-icon="mdi-calendar"
          hide-details
          dense

          clearable
          :value="filters.initialDate"
          @input="(newValue) => updateFilters({ initialDate: newValue })"

          :picker-props="{
            allowedDates: getAllowedDates,
            max: filters.finalDate,
          }"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <date-picker-field
          label="Data final"
          prepend-icon="mdi-calendar"
          hide-details
          dense

          @input="(newValue) => updateFilters({ finalDate: newValue })"
          clearable
          :value="filters.finalDate"

          :picker-props="{
            allowedDates: getAllowedDates,
            min: filters.initialDate,
          }"
        />
      </v-col>

      <v-col cols="12" lg="6">
        <house-funds-selector
          label="Fundos"
          hide-details
          dense

          multiple
          clearable
          small-chips
          deletable-chips

          item-text="shortName"
          item-value="_id"
          :value="filters.houseFundIds"
          @change="(newValue) => updateFilters({ houseFundIds: newValue })"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';

import DatePickerField from '@/components/global/DatePickerField.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';

export default {
  name: 'LiabilitiesFilters',

  components: {
    DatePickerField,
    HouseFundsSelector,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    updateFilters(newValues) {
      this.$emit('update:filters', { ...this.filters, ...newValues });
    },
  },
};
</script>
