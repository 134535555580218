var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","content-class":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipEvents = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"primary","to":("/liabilities/" + (item.houseFundId) + "?refDate=" + (item.refDate.substring(0, 10)))}},'v-btn',tooltipAttrs,false),tooltipEvents),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Ver detalhes ")]},proxy:true}],null,true)})]}},{key:"item.refDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.refDate))+" ")]}},{key:"item.currentPFeeGrossPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatQuota(item.currentPFeeGrossPrice))+" ")]}},{key:"item.currentPFeeNetPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatQuota(item.currentPFeeNetPrice))+" ")]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }