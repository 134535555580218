<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"

    :headers="headers"
    item-key="_id"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <template v-slot:item.quotaholderName="{ item }">
      <span class="text-no-wrap">
        {{ item.quotaholderName }}
      </span>
    </template>

    <template v-slot:item.purchaseDate="{ item }">
      {{ formatDate(item.purchaseDate) }}
    </template>

    <template v-slot:item.purchasePrice="{ item }">
      <span :class="{ 'error--text': item.purchasePrice < 0 }">
        {{ formatQuota(item.purchasePrice) }}
      </span>
    </template>

    <template v-slot:item.quantity="{ item }">
      <span :class="{ 'error--text': item.quantity < 0 }">
        {{ formatNumber(item.quantity) }}
      </span>
    </template>

    <template v-slot:item.purchaseValue="{ item }">
      <span :class="{ 'error--text': item.purchaseValue < 0 }">
        {{ formatMoney(item.purchaseValue) }}
      </span>
    </template>

    <template v-slot:item.redeemedValue="{ item }">
      <span :class="{ 'error--text': item.redeemedValue < 0 }">
        {{ formatMoney(item.redeemedValue) }}
      </span>
    </template>

    <template v-slot:item.currentPFeeGrossValue="{ item }">
      <span :class="{ 'error--text': item.currentPFeeGrossValue < 0 }">
        {{ formatMoney(item.currentPFeeGrossValue) }}
      </span>
    </template>

    <template v-slot:item.currentTaxGrossValue="{ item }">
      <span :class="{ 'error--text': item.currentTaxGrossValue < 0 }">
        {{ formatMoney(item.currentTaxGrossValue) }}
      </span>
    </template>

    <template v-slot:item.performanceFeeBaseDate="{ item }">
      {{ formatDate(item.performanceFeeBaseDate) }}
    </template>

    <template v-slot:item.performanceFee="{ item }">
      <span :class="{ 'error--text': item.performanceFee < 0 }">
        {{ formatMoney(item.performanceFee) }}
      </span>
    </template>

    <template v-slot:item.pFeeBenchmarkReturn="{ item }">
      <span :class="{ 'error--text': item.pFeeBenchmarkReturn < 0 }">
        {{ formatReturn(item.pFeeBenchmarkReturn) }}
      </span>
    </template>

    <template v-slot:item.pFeeBaseReturn="{ item }">
      <span :class="{ 'error--text': item.pFeeBaseReturn < 0 }">
        {{ formatReturn(item.pFeeBaseReturn) }}
      </span>
    </template>

    <template v-slot:item.btdReturn="{ item }">
      <span :class="{ 'error--text': item.btdReturn < 0 }">
        {{ formatPercentage(item.btdReturn) }}
      </span>
    </template>

    <template v-slot:item.houseFundTotalRepresentation="{ item }">
      <span :class="{ 'error--text': item.houseFundTotalRepresentation < 0 }">
        {{ formatPercentage(item.houseFundTotalRepresentation) }}
      </span>
    </template>

    <template v-slot:item.performanceFeeBasePrice="{ item }">
      <span :class="{ 'error--text': item.performanceFeeBasePrice < 0 }">
        {{ formatQuota(item.performanceFeeBasePrice) }}
      </span>
    </template>

    <template v-slot:item.performanceFeeBaseValue="{ item }">
      <span :class="{ 'error--text': item.performanceFeeBaseValue < 0 }">
        {{ formatMoney(item.performanceFeeBaseValue) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  formatDate,
  formatMoney,
  formatNumber,
  formatPercentage,
  formatQuota,
} from '@/utils/format-utils';

import copyToClipboard from '@/utils/copy-to-clipboard';

export default {
  name: 'LiabilitiesDetailsTable',

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    rawHeaders: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '48px',
      },

      {
        value: 'quotaholderName',
        text: 'Cotista',
      },

      {
        value: 'quantity',
        text: 'Quantidade',
        align: 'end',
      },

      {
        value: 'performanceFeeBaseDate',
        text: 'Data Base Perf.',
      },

      {
        value: 'performanceFeeBasePrice',
        text: 'Cota Base Perf.',
        align: 'end',
      },

      {
        value: 'performanceFeeBaseValue',
        text: 'Valor Base Perf.',
        align: 'end',
      },

      {
        value: 'performanceFee',
        text: 'Taxa Perf.',
        align: 'end',
      },

      {
        value: 'pFeeBenchmarkReturn',
        text: 'Rent. Benchmark',
        align: 'end',
      },

      {
        value: 'pFeeBaseReturn',
        text: 'Retorno (Perf.)',
        align: 'end',
      },

      {
        value: 'btdReturn',
        text: 'Retorno (Início)',
        align: 'end',
      },

      {
        value: 'purchaseValue',
        text: 'Valor Aplicado',
        align: 'end',
      },

      {
        value: 'redeemedValue',
        text: 'Valor Resgatado',
        align: 'end',
      },

      {
        value: 'purchasePrice',
        text: 'Cota Aplicação',
        align: 'end',
      },

      {
        value: 'purchaseDate',
        text: 'Data Aplicação',
      },

      {
        value: 'currentPFeeGrossValue',
        text: 'Valor Bruto Perf.',
        align: 'end',
      },

      {
        value: 'currentTaxGrossValue',
        text: 'Valor Bruto IR',
        align: 'end',
      },

      {
        value: 'houseFundTotalRepresentation',
        text: '%PL',
        align: 'end',
      },
    ],
  }),

  computed: {
    headers: (vm) => (vm.columns.length ? vm.rawHeaders.filter((item) => vm.columns.includes(item.value)) : vm.rawHeaders),
  },

  methods: {
    formatDate,
    formatMoney,
    formatNumber,
    formatPercentage,
    formatQuota,
    formatReturn: (val) => formatPercentage(val),

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>
