<template>
  <v-container fluid class="liabilities-details">
    <h1 class="text-h5">Detalhes da posição</h1>

    <v-container fluid>
      <v-row>
        <v-col>
          <house-funds-selector
            label="Fundo"
            hide-details
            dense
            item-text="shortName"
            item-value="_id"
            :value="houseFundId"
            @change="(val) => changeHouseFundId(val)"
          />
        </v-col>

        <v-col>
          <date-picker-field
            label="Data"
            hide-details
            dense
            v-model="refDate"
            :picker-props="{ allowedDates: getAllowedDates }"
            prepend-icon="mdi-arrow-left"
            @click:prepend="() => prevDate()"
            append-outer-icon="mdi-arrow-right"
            @click:append-outer="() => nextDate()"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-progress-linear
      top
      absolute
      indeterminate
      color="#9d6c32"
      :active="isFetchingData"
    />

    <template v-if="hasFoundData">
      <v-card>
        <v-card-actions class="justify-end pt-4">
          <v-btn
            class="mr-2 pr-3"
            small
            rounded
            color="primary"
            @click="downloadCsv"
          >
            <v-icon small left> mdi-microsoft-excel </v-icon>
            Baixar
          </v-btn>
          <v-badge
            overlap
            color="info"
            :content="filterCount"
            :value="filterCount > 0"
          >
            <v-btn
              small
              rounded
              color="primary"
              @click="isFiltersDialogOpen = true"
            >
              <v-icon small left> mdi-filter </v-icon>

              Filtros
            </v-btn>
          </v-badge>
        </v-card-actions>

        <v-dialog v-model="isFiltersDialogOpen" max-width="400">
          <v-card>
            <v-card-title> Filtrar itens </v-card-title>

            <liabilities-details-filters
              :filters.sync="filters"
              :filter-options="filterOptions"
            />

            <v-card-actions class="justify-end">
              <v-btn color="primary" @click="isFiltersDialogOpen = false">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card-text>
          <liabilities-details-overview
            :overview="overview"
            :current-p-fee-gross-price="details.currentPFeeGrossPrice"
            :current-p-fee-net-price="details.currentPFeeNetPrice"
          />
        </v-card-text>

        <v-card-text>
          <liabilities-details-table
            dense
            fixed-header
            :columns="detailsColumns"
            must-sort
            sort-by="btdReturn"
            sort-desc
            :items="filteredLiabilities"
            :items-per-page="-1"
            hide-default-footer
          />
        </v-card-text>
      </v-card>
    </template>

    <template v-else-if="hasRequestedData">
      <empty-state
        icon="mdi-calendar-remove"
        title="Posição não encontrada"
        message="Não há posições de passivos para o dia e fundo em questão."
      />
    </template>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';
/* eslint-disable-next-line */
import { loadValueFromUrl, saveValueOnUrl } from '@/utils/router-utils';
import { generateCSV } from '@/utils/json-to-csv';

import DatePickerField from '@/components/global/DatePickerField.vue';
import EmptyState from '@/components/global/EmptyState.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';

import LiabilitiesDetailsTable from './LiabilitiesDetailsTable.vue';
import LiabilitiesDetailsFilters from './LiabilitiesDetailsFilters.vue';
import LiabilitiesDetailsOverview from './LiabilitiesDetailsOverview.vue';

import {
  filterResults,
  getFiltersOptions,
  getOverview,
} from './utils/data-handling-utils';

export default {
  name: 'LiabilitiesDetailsView',

  components: {
    DatePickerField,
    EmptyState,
    HouseFundsSelector,

    LiabilitiesDetailsTable,
    LiabilitiesDetailsFilters,
    LiabilitiesDetailsOverview,
  },

  props: {
    houseFundId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    refDate: loadValueFromUrl('refDate') ?? '',

    details: null,
    isFetchingData: false,
    hasRequestedData: false,

    filters: {
      quotaholderName: '',
      purchaseDate: [],
      performanceFeeBaseDate: [],
    },

    isFiltersDialogOpen: false,

    // TODO: Implement columns filters
    isColumnsDialogOpen: false,
    detailsColumns: [],
  }),

  computed: {
    hasFoundData: (vm) => Object.keys(vm.details ?? {}).length > 0,

    filterCount: (vm) => Object.values(vm.filters).filter((val) => (Array.isArray(val) ? val.length > 0 : !!val)).length,
    filterOptions: (vm) => getFiltersOptions(vm.details?.liabilities ?? []),
    filteredLiabilities: (vm) => filterResults(vm.details?.liabilities ?? [], vm.filters),

    overview: (vm) => getOverview(vm.filteredLiabilities),
  },

  watch: {
    refDate(newValue) {
      saveValueOnUrl('refDate', newValue);

      this.details = null;
      this.hasRequestedData = false;
      this.fetchData();
    },

    houseFundId() {
      this.details = null;
      this.hasRequestedData = false;
      this.fetchData();
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
    this.fetchData();
  },

  methods: {
    downloadCsv() {
      generateCSV(this.filteredLiabilities);
    },

    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    prevDate() {
      const targetDate = moment(this.refDate).prevBusinessDay('brasil');
      this.refDate = targetDate.format('YYYY-MM-DD');
    },

    nextDate() {
      const targetDate = moment(this.refDate).nextBusinessDay('brasil');
      this.refDate = targetDate.format('YYYY-MM-DD');
    },

    changeHouseFundId(newId) {
      const { hash, query } = this.$router.currentRoute;
      this.$router.replace({ path: `/liabilities/${newId}`, hash, query });
    },

    async fetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.liabilities.getLiabilitiesDetails(
          this.houseFundId,
          this.refDate,
        );

        this.details = data;

        if (data && !this.refDate) {
          this.refDate = data.refDate?.substring(0, 10);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.hasRequestedData = true;
        this.isFetchingData = false;
      }
    },
  },
};
</script>
