<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"

    :value="value"
    :items="sortedItems"

    :loading="isLoading"
    :search-input.sync="searchQuery"
    :filter="customFilter"

    @change="() => searchQuery = null"
    @focus="() => fetchItems()"
  >
    <template v-slot:prepend-item v-if="showSelectAll && hasResults">
      <v-list-item @click="() => toggleSelectAll()">
        <v-list-item-action>
          <v-icon :color="selectAllIconColor">
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            Selecionar todos
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
    </template>

    <template v-slot:selection="{ item, index }" v-if="ellipsedSelection">
      <span v-if="index < ellipsedSelectionLimit">
        {{ item[$attrs['item-text']] || item.shortName }}
      </span>

      <span class="mr-1" v-if="index < Math.min(ellipsedSelectionLimit, value.length) - 1">
        ,
      </span>

      <span class="ml-1" v-if="index === ellipsedSelectionLimit">
        e mais {{ value.length - ellipsedSelectionLimit }}...
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import store from '@/store';

export default {
  name: 'HouseFundsSelector',

  inheritAttrs: false,

  // NOTE: "value" must be set as a prop for v-model to work properly
  // @see (https://github.com/vuejs/vue/issues/8430)
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    showSelectAll: {
      type: Boolean,
      default: false,
    },

    ellipsedSelection: {
      type: Boolean,
      default: false,
    },

    ellipsedSelectionLimit: {
      type: Number,
      default: 4,
    },
  },

  data: () => ({
    searchQuery: null,
  }),

  computed: {
    isLoading: () => store.getters['houseFunds/isLoading'] ?? false,
    items: () => store.getters['houseFunds/activeHouseFunds'] ?? [],
    hasResults: (vm) => vm.items.length > 0,

    sortedItems: (vm) => {
      const items = [...vm.items];

      return items.sort((itemA, itemB) => (itemA.shortName > itemB.shortName ? 1 : -1));
    },

    hasSelectedAll: (vm) => vm.value.length === vm.items.length,
    hasSelectedSome: (vm) => vm.value.length > 0 && !vm.hasSelectedAll,
    selectAllIconColor: (vm) => (vm.value.length > 0 ? 'indigo darken-4' : ''),
    selectAllIcon: (vm) => {
      if (vm.hasSelectedAll) return 'mdi-close-box';
      if (vm.hasSelectedSome) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    customFilter(item, queryText) {
      const query = queryText.toLowerCase();
      const index1 = item.shortName.toLowerCase();

      return index1.includes(query);
    },

    toggleSelectAll() {
      if (this.hasSelectedAll) {
        this.updateValue([]);
        return;
      }

      const itemValueKey = this.$attrs['item-value'];
      const allItems = itemValueKey ? this.items.map((item) => item[itemValueKey]) : this.items;

      this.updateValue(allItems);
    },

    updateValue(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },

    async fetchItems() {
      const MIN_INTERVAL = 10 * 60 * 1000;

      const lastResponseDate = store.state.houseFunds?.lastResponseDate ?? null;
      const hasRequested = store.getters['houseFunds/hasRequested'] ?? false;

      const duration = Date.now() - Date.parse(lastResponseDate);

      if (hasRequested && duration <= MIN_INTERVAL) {
        return;
      }

      await store.dispatch('houseFunds/fetchList');
    },
  },
};
</script>
