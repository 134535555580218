export default function copyToClipboard(text: string) {
  const elem = document.createElement('input');

  elem.style.position = 'fixed';
  elem.style.height = '1px';
  elem.style.width = '1px';
  elem.style.opacity = '0';

  document.body.appendChild(elem);

  elem.value = text;
  elem.select();
  elem.setSelectionRange(0, 99999);

  document.execCommand('copy');

  elem.remove();
}
