import { Options, Parser } from 'json2csv';

// Gambiarra to force excel to open with UTF-8
const universalBOM = '\uFEFF';
interface GenerateCSVOptions<T> extends Options<T> {
  download?: boolean;
  fileName?: string;
  fileType?: string;
}

/**
 * generateCSV
 *
 * @param json {string | T}
 * @param opts {Options}
 * @returns {string}
 */
export function generateCSV<T>(json: string | T, {
  download = true, fileName, fileType = 'text/csv', ...opts
}: GenerateCSVOptions<T> = {}): string {
  let myData = json;

  if (typeof json === 'string') myData = JSON.parse(json);

  let name = fileName;
  if (!name) name = `${new Date().getTime()}.${fileType.split('/')[1]}`;

  const parser = new Parser(opts);

  let csv = universalBOM;

  csv += parser.parse(myData as T);

  const blob = new Blob([csv], { type: `${fileType};charset=utf8` });

  const url = window.URL.createObjectURL(blob);

  if (download) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return url;
}
