/* eslint-disable import/prefer-default-export */
export function parseRequestFilters(rawFilters: Record<string, any>) {
  const houseFundIds = rawFilters.houseFundIds ?? [];

  const initialDate = rawFilters.initialDate ?? '1970-01-01';
  const finalDate = rawFilters.finalDate ?? '2999-01-01';

  return {
    houseFunds: houseFundIds.length > 0 ? houseFundIds.join(',') : null,
    refDate: `${initialDate}:${finalDate}`,
  };
}
