var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipProps){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.copyId(item._id); }}},'v-btn',tooltipProps.attrs,false),tooltipProps.on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Copiar _id ")]},proxy:true}],null,true)})]}},{key:"item.quotaholderName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.quotaholderName)+" ")])]}},{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.purchaseDate))+" ")]}},{key:"item.purchasePrice",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.purchasePrice < 0 }},[_vm._v(" "+_vm._s(_vm.formatQuota(item.purchasePrice))+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.quantity < 0 }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity))+" ")])]}},{key:"item.purchaseValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.purchaseValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.purchaseValue))+" ")])]}},{key:"item.redeemedValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.redeemedValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.redeemedValue))+" ")])]}},{key:"item.currentPFeeGrossValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.currentPFeeGrossValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.currentPFeeGrossValue))+" ")])]}},{key:"item.currentTaxGrossValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.currentTaxGrossValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.currentTaxGrossValue))+" ")])]}},{key:"item.performanceFeeBaseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.performanceFeeBaseDate))+" ")]}},{key:"item.performanceFee",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.performanceFee < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.performanceFee))+" ")])]}},{key:"item.pFeeBenchmarkReturn",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.pFeeBenchmarkReturn < 0 }},[_vm._v(" "+_vm._s(_vm.formatReturn(item.pFeeBenchmarkReturn))+" ")])]}},{key:"item.pFeeBaseReturn",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.pFeeBaseReturn < 0 }},[_vm._v(" "+_vm._s(_vm.formatReturn(item.pFeeBaseReturn))+" ")])]}},{key:"item.btdReturn",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.btdReturn < 0 }},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.btdReturn))+" ")])]}},{key:"item.houseFundTotalRepresentation",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.houseFundTotalRepresentation < 0 }},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.houseFundTotalRepresentation))+" ")])]}},{key:"item.performanceFeeBasePrice",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.performanceFeeBasePrice < 0 }},[_vm._v(" "+_vm._s(_vm.formatQuota(item.performanceFeeBasePrice))+" ")])]}},{key:"item.performanceFeeBaseValue",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': item.performanceFeeBaseValue < 0 }},[_vm._v(" "+_vm._s(_vm.formatMoney(item.performanceFeeBaseValue))+" ")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }