<template>
  <v-container fluid class="liabilities-management">
    <h1 class="text-h5">Posições de passivos</h1>

    <liabilities-filters :filters.sync="requestFilters" />

    <div class="text-right my-4">
      <v-btn small rounded color="primary" @click="() => fetchData()">
        <v-icon small left>
          mdi-refresh
        </v-icon>

        Atualizar
      </v-btn>
    </div>

    <v-card>
      <v-card-text>
        <liabilities-table
          :items="results"
          :loading="isFetchingData"

          fixed-header
          dense

          must-sort
          sort-by="refDate"
          sort-desc

          :items-per-page="-1"
          hide-default-footer
        />
        <v-row v-if="showLoadMore" class="my-2" align="center" justify="space-around">
          <v-btn :loading="isFetchingData" @click="() => loadMore()" small color="primary">Carregar mais</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import moment from 'moment-loyall';

import api from '@/services/api';
/* eslint-disable-next-line */
import { loadValueFromUrl, saveValuesOnUrl } from '@/utils/router-utils';

import { parseRequestFilters } from './utils/request-utils';
import LiabilitiesFilters from './LiabilitiesFilters.vue';
import LiabilitiesTable from './LiabilitiesTable.vue';

export default {
  name: 'LiabilitiesManagementView',

  components: {
    LiabilitiesFilters,
    LiabilitiesTable,
  },

  data: () => ({
    requestFilters: {
      houseFundIds: loadValueFromUrl('houseFundIds')?.split(',') ?? [],
      initialDate: loadValueFromUrl('initialDate') ?? moment().businessSubtract(5, 'days', 'brasil').format('YYYY-MM-DD'),
      finalDate: loadValueFromUrl('finalDate') ?? moment().format('YYYY-MM-DD'),
    },

    results: [],
    total: 0,
    isFetchingData: false,
  }),

  computed: {
    showLoadMore: (vm) => vm.total > vm.results.length,
  },

  watch: {
    requestFilters: {
      deep: true,
      immediate: true,
      handler(newFilters) {
        const houseFundIds = newFilters.houseFundIds.join(',');
        saveValuesOnUrl({ ...newFilters, houseFundIds });

        this.fetchData();
      },
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
  },

  methods: {
    async loadMore() {
      this.isFetchingData = true;

      try {
        const { data } = await api.liabilities.getLiabilitiesList({
          params: {
            ...parseRequestFilters(this.requestFilters),
            offset: this.results.length,
          },
        });
        this.total = data.total;
        this.results = [...this.results, ...data.results] ?? [];
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
        });
      } finally {
        this.isFetchingData = false;
      }
    },
    fetchData: debounce(async function debouncedFetchData() {
      this.isFetchingData = true;
      try {
        const { data } = await api.liabilities.getLiabilitiesList({
          params: parseRequestFilters(this.requestFilters),
        });

        this.results = data.results;
        this.total = data.total;
      } catch (error) {
        console.log(error);
      } finally {
        this.isFetchingData = false;
      }
    }, 200),
  },
};
</script>
